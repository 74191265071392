
import { computed, defineComponent, reactive, ref, toRefs, watch, nextTick, onMounted } from "vue";
import useFetchSearch from "./hooks/useFetchSearch";
import { getSession, STATUS } from "@/utils";
import { searchParams as iterationId } from "@/views/iteration/useMixin";
import useGetTableData from "@/composables/useGetTableData";
import { useRouter } from "vue-router";
import IterationTip from "@/components/iteration-tip/index.vue";
import useWatchChangeProduct from "@/composables/useWatchChangeProduct";
import FullScreen from "@/components/fullscreen/index.vue";
import type { SearchArray } from "@/types/interface";
import { USER } from "@/store/state";
import dayjs from "dayjs";
import { DemandItemResp } from "@/composables/useCommon";
import { store } from "@/store";
import { ElMessage } from "element-plus";
import { MutationType } from "@/store/mutation-types";
import useBoardBase from "@/composables/useBoardBase";
import { systemBehaviorStore } from "@/utils/contantOptions";

export default defineComponent({
  name: "pmo",
  components: { IterationTip, FullScreen },
  setup() {
    const isFullScreen = ref(false); // 是否全屏
    const pageTableRef = ref();
    const searchParams = reactive<{
      name: string | undefined;
      status: number | number[] | undefined;
      start_time: string | undefined;
      end_time: string | undefined;
      staff_no: string | undefined;
      demand_id: string | undefined;
      month: string | undefined;
      sort_name: string | undefined;
      sort_by: number | undefined;
      product_id: number[] | undefined;
      page_index: number;
      page_size: number;
    }>({
      page_index: 1,
      page_size: 20,
      sort_name: undefined,
      sort_by: undefined,
      product_id: [],
      month: "",
      name: "",
      status: [],
      start_time: "",
      end_time: "",
      staff_no: "",
      demand_id: ""
    });

    const router = useRouter();
    const user = getSession(systemBehaviorStore.systemUserinfo, true) as USER;
    const { useInitGetProductList, useGetMultipleProductStaffList, useGetMultipleProductDemandList, boardBaseRt } = useBoardBase();

    const dataMap = new Map();

    const currentActive = ref("-1");

    // @ts-ignore
    const searchArray: SearchArray = reactive({
      btnArray: [
        { id: "-1", label: "所有", key: "all" },
        { id: "0,1,2,3,4,5,7,8", label: "未完成", key: "status" },
        { id: "6", label: "已发布", key: "status" },
        { id: "3", label: "我参与的", key: "staff_no" },
        { id: "4", label: "本月", key: "month" },
        { id: "5", label: "上月", key: "month" }
      ],
      searchForm: [
        {
          type: "input",
          label: "名称",
          key: "name",
          val: "",
          placeholder: "请输入迭代名称"
        },
        {
          type: "select",
          label: "状态",
          val: [],
          multiple: true,
          key: "status",
          valueKey: ["value", "label"],
          listData: STATUS
        },
        {
          type: "select",
          label: "成员",
          val: [],
          key: "staff_no",
          valueKey: ["staff_no", "staff_name"],
          listData: []
        },
        {
          type: "select",
          selectType: "virtual",
          label: "关联需求",
          key: "demand_id",
          val: "",
          listData: []
        },
        {
          type: "daterange",
          label: "时间",
          val: [],
          key: "create_time",
          hasShortcuts: true,
          valueKey: []
        }
      ]
    });

    const curStatus = computed(function () {
      return function (row: any) {
        const { complete_percent, time_percent } = row;
        return complete_percent - time_percent;
      };
    });

    // 监听项目集数据变化
    watch(
      () => boardBaseRt.currentProIds,
      (newVal) => {
        searchParams.product_id = newVal;
        nextTick(() => {
          handleConditionSearch();
        });
      },
      {
        deep: true,
        immediate: true
      }
    );

    onMounted(() => {
      useInitGetProductList();
    });

    const tableRowClassName = ({ row }: { row: Record<string, any>; rowIndex: number }) => {
      const { status, complete_percent, time_percent } = row;
      if (status === 0) {
        // 未开始
        return "no-start";
      } else if (status === 6 || status === 7) {
        // 已完成, 已搁置
        return "has-end";
      } else if (complete_percent - time_percent < 0) {
        // 延期
        return "delay";
      }
      // 进行中
      return "to-do";
    };

    // 查询表单搜索
    const quickSeacrh = (val: Record<string, any>) => {
      Object.keys(searchParams)
        .filter((i) => !["sort_name", "sort_by", "product_id", "page_index", "page_size"].includes(i))
        .forEach((key: string) => {
          // @ts-ignore
          searchParams[key as keyof typeof searchParams] = undefined;
        });

      switch (val.key) {
        case "status":
          searchParams.status = [val.id === "2" ? Number(val.id) : val.id.split(",")?.map((i: string) => Number(i))];
          break;
        case "staff_no":
          searchParams.staff_no = user!.staff_no;
          break;
        case "month":
          if (val.id === "4") {
            // 本月
            searchParams.month = dayjs().format("YYYY-MM");
          } else {
            // 上个月
            searchParams.month = dayjs().add(-1, "month").startOf("month").format("YYYY-MM");
          }
          break;
        default:
          break;
      }
      searchParams.product_id = boardBaseRt.currentProIds;
      handleConditionSearch();
    };

    const changeSearch = (val: Record<string, any>) => {
      Object.keys(searchParams)
        .filter((i) => !["sort_name", "sort_by", "product_id", "page_index", "page_size"].includes(i))
        .forEach((key: string) => {
          if (key === "start_time" && val.create_time) {
            searchParams.start_time = val.create_time[0];
          } else if (key === "end_time" && val.create_time) {
            searchParams.end_time = val.create_time[1];
          } else {
            // @ts-ignore
            searchParams[key as keyof typeof searchParams] = val[key] || undefined;
          }
        });
      searchParams.product_id = boardBaseRt.currentProIds;

      handleConditionSearch();
    };

    const getStatus = (index: number) => {
      const status = STATUS.find((v) => v.value === index);
      return status?.label;
    };

    const { getData, tableData, stopAutoGetData, currentPage } = useGetTableData(useFetchSearch, searchParams);
    let timer: ReturnType<typeof setTimeout>;
    const handleConditionSearch = async (isHiddenSelect?: boolean) => {
      if (isHiddenSelect === true) {
        return;
      }
      pageTableRef.value?.setCurrentPage();
      currentPage.value = 1;
      clearTimeout(timer);
      timer = setTimeout(async () => {
        // 获取当前page_size，如果有缓存，不设置，没有缓存默认第一页
        await getData({ pageSize: searchParams.page_size, pageIndex: 1 }, true, searchParams);
        stopAutoGetData.value = false;
        const contentHeight = document.getElementsByClassName("content")[0] as HTMLDivElement;
        const headerHeight = document.getElementsByClassName("search-header")[0] as HTMLDivElement;
        pageTableRef.value.height = contentHeight.offsetHeight - headerHeight.offsetHeight - 115;
      }, 300);
    };
    const progressNode = (row: Record<string, any>) => {
      let createTime = row.plan ? row.plan.create_time : "";
      return [
        {
          time: createTime,
          realTime: createTime
        },
        {
          time: row.dev_time,
          realTime: row.real_dev_time
        },
        {
          time: row.union_time,
          realTime: row.real_union_time
        },
        {
          time: row.test_time,
          realTime: row.real_test_time
        },
        {
          time: row.release_time,
          realTime: row.real_release_time
        }
      ];
    };
    const handleClear = () => {
      searchParams.name = "";
      handleConditionSearch();
    };
    useWatchChangeProduct(handleConditionSearch, () => {
      handleConditionSearch();
    });
    const handleShowDetail = (item: Record<string, any>) => {
      store.commit(MutationType.updateIterateId, item?.id);
      store.commit(MutationType.updateCurrentIter);
      store.commit(MutationType.updateProductId, item?.product_id);
      iterationId.demand = item?.id;
      const { href } = router.resolve({
        name: "homepage",
        query: { productId: item?.product_id, iterateId: iterationId.demand }
      });

      window.open(href, "_blank");
    };
    // 排序
    const sortChange = (e: any) => {
      const { prop, order } = e;
      if (!order) return;
      searchParams.sort_name = prop;
      searchParams.sort_by = order === "ascending" ? 1 : 2;
      getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams);
    };

    const handleStatus = (val: number[]) => {
      if (!val.length) {
        searchParams.status = [];
        handleConditionSearch();
      }
    };
    const getTextColor = (status: number, type = "color") => {
      const list = STATUS.find((v: { value: number; label: string }) => v.value === status);
      return {
        [type]: list?.color
      };
    };

    // 获取成员list
    const getStaffList = async () => {
      const data = await useGetMultipleProductStaffList({ product_id: boardBaseRt.currentProIds });
      return data;
    };

    // 获取成员list
    const getDemandList = async () => {
      const data = await useGetMultipleProductDemandList({ product_id: boardBaseRt.currentProIds });
      return data;
    };
    // 点击的时候请求需求和成员列表
    const onSearchFromClick = async () => {
      const name = boardBaseRt.currentGroupIds.toString();
      console.log(name, "name", dataMap);

      if (!dataMap.has("data" + name)) {
        const staffList = await getStaffList();
        const demandList = await getDemandList();
        // 如果两个都请求成功，就赋值
        if (staffList && demandList) {
          dataMap.set("data" + name, { staffList, demandList });
          // 请求成功，清空time
          dataMap.set("time", 0);
        } else if (!dataMap.get("time") || dataMap.get("time") <= 1) {
          // 如果没有请求成功需要再次请求
          let time = dataMap.get("time") || 0;
          dataMap.set("time", time ? ++time : 1);
          onSearchFromClick();
          return;
        }
      }
      if (!dataMap.get("data" + name)) return ElMessage.warning("数据异常，请刷新页面");
      const { staffList, demandList } = dataMap.get("data" + name);

      searchArray.searchForm[2].listData = staffList ?? [];
      searchArray.searchForm[3].listData =
        demandList?.map((item: DemandItemResp) => {
          return {
            value: item.id,
            label: item.name
          };
        }) ?? [];
    };
    return {
      stopAutoGetData,
      sortChange,
      onSearchFromClick,
      changeSearch,
      quickSeacrh,
      searchArray,
      curStatus,
      isFullScreen,
      getTextColor,
      getStatus,
      getData,
      handleShowDetail,
      progressNode,
      handleConditionSearch,
      STATUS,
      ...toRefs(tableData),
      searchParams,
      pageTableRef,
      handleClear,
      handleStatus,
      currentPage,
      tableRowClassName,
      currentActive
    };
  }
});
